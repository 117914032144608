jQuery(function ($) {

  var sliderConfig = {
    arrows: false,
    speed: 350,
    variableWidth: false,
    adaptiveHeight: false,
    draggable: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  enableControls($('.slider-wrapper .entries'));
  $('.slider-wrapper .entries').slick(sliderConfig);

  // $('.slider-wrapper .entries').each((i, el) => {
  //   let $this = $(el);
  //   var theConfig = sliderConfig;
  //   var minimum = 2;

  //   if($this.children().length > minimum) {
  //     // let blob = new Blob([$this.parent().html()], {type : 'text/html'});
  //     // let reader = new FileReader();
  //     // let parent = $this.parent();
  //     // reader.addEventListener('loadend', function(ev) {
  //     //   parent.attr('data-child', reader.result);
  //     // }.bind(parent));
  //     // reader.readAsText(blob);

  //     enableControls($this);
  //     $this.slick(theConfig);
  //   }
  // });

  $('.slider-wrapper .slider-controls .control').on( 'click', function (ev) {
    let $slide = $(this).parents('.slider-wrapper').find('.slider').eq(0);
    if($(this).hasClass('next')) {
      $slide.slick('slickNext');
    }
    else if($(this).hasClass('prev')) {
      $slide.slick('slickPrev');
    }
  });

  function enableControls($elm) {
    $elm
      .parents('.slider-wrapper')
      .eq(0)
      .find('.slider-controls')
      .eq(0)
      .addClass('active');
  }

} );